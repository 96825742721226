import { ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { type RouteData, type Perms, CoreService } from '/@src/assets/api'
import { useRealtimeStore } from '/@src/stores/realtime'
import { HasPerm, type PermsFlags } from '/@src/assets/types'
import { ChannelActivityService } from '/@src/assets/api-ws'
import { extractRoutePathPortionFromPath } from '/@src/assets/Utils'

export const useRouteData = defineStore('routeData', () => {
  const routeData = ref<Partial<RouteData>>({})
  const loading = ref(true)
  const xDomain = ref(window.location.host)
  const xTenant = ref<string | undefined>(undefined)
  let previousRoutePath: string | null = null

  async function loadRouteData(path?: string | null, force = false) {
    try {
      loading.value = true

      const headers = { xDomain: xDomain.value, xTenant: xTenant.value }

      // paths consist of two parts {routePath}/-/{appPath}
      const routePath = extractRoutePathPortionFromPath(path || '')
      if (!force && routePath === previousRoutePath) return // don't reload routes unnecessarily

      previousRoutePath = routePath
      const routeData =
        routePath.length === 0
          ? await CoreService.getRouteAtRoot(headers)
          : await CoreService.getRouteAtPath({ routePath, ...headers })
      await setRouteData(routeData.results || {})
    } finally {
      loading.value = false
    }
  }

  async function __updateRealtimeChannelSubscriptions(
    from: Partial<RouteData>,
    to: Partial<RouteData>
  ) {
    const remove: string[] = []
    const add: string[] = []

    if (from.user?.id && from.user.id.length > 0) remove.push(`user:${from.user.id}`)
    if (from.current?.tenantId && from.current.tenantId.length > 0)
      remove.push(`tenant:${from.current.tenantId}`)
    if (from.current?.initiativeId && from.current.initiativeId.length > 0)
      remove.push(`initiative:${from.current.initiativeId}`)
    if (from.current?.appId && from.current.appId.length > 0)
      remove.push(`app:${from.current.appId}`)
    if (from.current?.dashboardId && from.current.dashboardId.length > 0)
      remove.push(`dashboard:${from.current.dashboardId}`)
    if (from.current?.pageSetId && from.current.pageSetId.length > 0)
      remove.push(`pageset:${from.current.pageSetId}`)
    if (from.current?.pageId && from.current.pageId.length > 0)
      remove.push(`page:${from.current.pageId}`)

    from.groups?.forEach((x: string) => {
      remove.push(`group:${x}`)
    })

    if (to.user?.id && to.user.id.length > 0) add.push(`user:${to.user.id}`)
    if (to.current?.tenantId && to.current.tenantId.length > 0)
      add.push(`tenant:${to.current.tenantId}`)
    if (to.current?.initiativeId && to.current.initiativeId.length > 0)
      add.push(`initiative:${to.current.initiativeId}`)
    if (to.current?.appId && to.current.appId.length > 0)
      add.push(`app:${to.current.appId}`)
    if (to.current?.dashboardId && to.current.dashboardId.length > 0)
      add.push(`dashboard:${to.current.dashboardId}`)
    if (to.current?.pageSetId && to.current.pageSetId.length > 0)
      add.push(`pageset:${to.current.pageSetId}`)
    if (to.current?.pageId && to.current.pageId.length > 0)
      add.push(`page:${to.current.pageId}`)

    to.groups?.forEach((x: string) => {
      add.push(`group:${x}`)
    })

    // remove all item that are in both lists
    const realtimeStore = useRealtimeStore()
    await realtimeStore.unsubscribeFromChannels(remove.filter((r) => !add.includes(r)))
    await realtimeStore.subscribeToChannels(add.filter((r) => !remove.includes(r)))
  }

  async function setRouteData(data: RouteData) {
    const from = { ...routeData.value }
    const to = data || {}

    routeData.value = to
    xTenant.value = routeData.value.current?.tenantId || undefined

    await __updateRealtimeChannelSubscriptions(from, to)

    // TBD: the following is no longer needed as it happens inside the realtime
    //      server, when subscribing and unsubscribing to channels
    try {
      // const activityHeartbeatRequest:
      const realtimeStore = useRealtimeStore()
      await ChannelActivityService.postApiActivityHeartbeat({
        requestBody: {
          channels: realtimeStore.activeChannels,
        },
      })
    } catch {}
  }

  // function setLoading(newLoading: boolean) {
  //   loading.value = newLoading
  // }

  const currentTenant = computed(() => {
    return xTenant.value
      ? routeData.value.tenants?.find((t) => t.id === xTenant.value) || null
      : null
  })

  const currentInitiative = computed(() => {
    return routeData.value.current?.initiativeId
      ? routeData.value.initiatives?.find(
          (t) => t.id === routeData.value.current?.initiativeId
        ) || null
      : null
  })

  const currentApp = computed(() => {
    return routeData.value.current?.appId
      ? routeData.value.apps?.find((t) => t.id === routeData.value.current?.appId) || null
      : null
  })

  const currentAppUrl = computed(() => {
    return currentInitiative.value && currentApp.value
      ? `/${currentInitiative.value?.slug}/${currentApp.value?.slug}`
      : null
  })

  const currentDocsManagerApp = computed(() => {
    return routeData.value.apps?.find(
      (t) =>
        t.initiativeId === routeData.value.current?.initiativeId &&
        t.appDefId?.toUpperCase() === '56DEF7C9-8623-45A9-8511-CE4FAB128D7B'
    )
  })

  const currentDashboard = computed(() => {
    return routeData.value.current?.dashboardId
      ? routeData.value.dashboards?.find(
          (t) => t.id === routeData.value.current?.dashboardId
        ) || null
      : null
  })

  const currentPageSet = computed(() => {
    return routeData.value.current?.pageSetId
      ? routeData.value.pageSets?.find(
          (t) => t.id === routeData.value.current?.pageSetId
        ) || null
      : null
  })

  const currentPage = computed(() => {
    return routeData.value.current?.pageId
      ? routeData.value.pages?.find((t) => t.id === routeData.value.current?.pageId) ||
          null
      : null
  })

  const currentUser = computed(() => {
    return routeData.value.user?.id ? routeData.value.user! : null
  })

  const isAuthenticated = computed(() => {
    return routeData.value.isAuthenticated
  })

  const isSystemAdmin = computed(() => {
    return routeData.value.user?.isSystemAdmin === true
  })

  function can(
    target: (any & { perms?: Perms | undefined }) | undefined,
    targetOperation: PermsFlags
  ) {
    if (!target || !target.perms) return false
    const hasIt = isSystemAdmin.value === true || HasPerm(target.perms, targetOperation)
    return hasIt
  }

  return {
    xDomain,
    xTenant,
    loading,
    // setLoading,
    routeData,
    loadRouteData,
    currentTenant,
    currentInitiative,
    currentApp,
    currentAppUrl,
    currentDocsManagerApp,
    currentDashboard,
    currentPageSet,
    currentPage,
    currentUser,
    isAuthenticated,
    isSystemAdmin,
    hideAppSideLink: computed(() => {
      return (
        (currentApp.value?.meta?.hideAppSideLink &&
          Boolean(currentApp.value?.meta?.hideAppSideLink)) ||
        false
      )
    }),
    hideDashboardSideLink: computed(() => {
      return (
        (currentApp.value?.meta?.hideDashboardSideLink &&
          Boolean(currentApp.value?.meta?.hideDashboardSideLink)) ||
        false
      )
    }),
    hidePageSideLink: computed(() => {
      return (
        (currentApp.value?.meta?.hidePageSideLink &&
          Boolean(currentApp.value?.meta?.hidePageSideLink)) ||
        false
      )
    }),
    dashboardsText: computed(() => {
      return {
        singular: currentApp.value?.meta?.renameDashboardTextTo || 'Dashboard',
        plural: currentApp.value?.meta?.renameDashboardsTextTo || 'Dashboards',
      }
    }),
    pageSetsText: computed(() => {
      return {
        singular: currentApp.value?.meta?.renamePageSetTextTo || 'Page Set',
        plural: currentApp.value?.meta?.renamePageSetsTextTo || 'Page Sets',
      }
    }),
    pagesText: computed(() => {
      return {
        singular: currentApp.value?.meta?.renamePageTextTo || 'Page',
        plural: currentApp.value?.meta?.renamePagesTextTo || 'Pages',
      }
    }),
    can,
  } as const
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRouteData, import.meta.hot))
}
