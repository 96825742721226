/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddToChannelsRequest } from '../models/AddToChannelsRequest';
import type { RemoveFromChannelsRequest } from '../models/RemoveFromChannelsRequest';
import type { SendMessageRequest } from '../models/SendMessageRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChannelConnectionsService {

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiAddToChannels({
requestBody,
}: {
requestBody: AddToChannelsRequest,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/add-to-channels',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiRemoveFromChannels({
requestBody,
}: {
requestBody: RemoveFromChannelsRequest,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/remove-from-channels',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiSendMessage({
requestBody,
}: {
requestBody: SendMessageRequest,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/send-message',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
