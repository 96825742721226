import { Perms } from './api'
import { ApiRequestOptions } from './api/core/ApiRequestOptions'

// re-export
export { Perms }

export enum PermsFlags {
  None,
  View = 1 << 0, // 1
  Create = 1 << 1, // 2
  Update = 1 << 2, // 4
  Delete = 1 << 3, // 8
  Manage = 1 << 4, // 16
}

/*
 Assign value:
 let x:PermsFlags = PermsFlags.View;

 Add flag value:
 x:PermsFlags |= PermsFlags.View;

 Remove flag value:
 x:PermsFlags &= ~PermsFlags.View;

 Check for flag value:
 let x:Boolean = (PermsFlags.View === (x & PermsFlags.View))
*/

export function HasPerm(perm: Perms | number | undefined, hasFlag: PermsFlags) {
  // the perm is actually always a number
  const permFlagNumber = <number>(perm as any)
  const hasThisFlag = hasFlag === (permFlagNumber & hasFlag)
  return hasThisFlag
}

export interface WebApiError {
  url: string
  status: number
  statusText: string
  body: WebApiException
  request: ApiRequestOptions
}

export interface WebApiException {
  type?: string
  statusCode: number
  message?: string
  errors?: {
    propertyName: string
    errorMessage?: string
    attemptedValue?: any
    customState?: any
    severity: 'Error' | 'Warning' | 'Info' | string
    errorCode?: string
  }[]
  stackTrace?: string
}
