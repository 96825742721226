/**
 * This is a store that hold left panel state
 * It could be one of the ActivePanelId
 *
 * Using useStorage from @vueuse/core allow persistance storage accross tabs/sessions
 *
 * We can import and set activePanel anywhere in our project
 * @see /src/components/partials/toolbars/Toolbar.vue
 * @see /src/components/partials/panels/ActivityPanel.vue
 */

import { acceptHMRUpdate, defineStore } from 'pinia'
import { ChannelConnectionsService } from '/@src/assets/api-ws'
import { uniq } from 'lodash-es'

// import { useStorage } from '@vueuse/core'

export const useRealtimeStore = defineStore('realtime', () => {
  // const cid = useStorage<string>('cid', '')
  const cid = ref('')

  // active channels user is subscribed to
  const activeChannels = ref<string[]>([])

  async function setConnectionId(connectionId: string) {
    cid.value = connectionId

    if (activeChannels.value.length > 0) {
      await subscribeToChannels(activeChannels.value, true)
    }
  }

  async function subscribeToChannels(
    channels: string | string[],
    force: boolean = false
  ) {
    // console.log(subscribeToChannels, channels)
    const newChannels = (Array.isArray(channels) ? channels : [channels]).filter(
      (c) => force || !activeChannels.value.includes(c)
    )
    try {
      if (newChannels.length > 0 && cid.value && cid.value.length > 0)
        await ChannelConnectionsService.postApiAddToChannels({
          requestBody: {
            connectionId: cid.value,
            channels: newChannels,
          },
        })
      activeChannels.value = uniq([...activeChannels.value, ...newChannels])
    } catch (err) {
      console.log(`Unable to subscribe to channels: ` + err)
    }
  }

  async function unsubscribeFromChannels(
    channels: string | string[],
    force: boolean = false
  ) {
    // console.log(unsubscribeFromChannels, channels)
    const removeChannels = (Array.isArray(channels) ? channels : [channels]).filter(
      (c) => force || activeChannels.value.includes(c)
    )
    try {
      if (removeChannels.length > 0 && cid.value && cid.value.length > 0)
        await ChannelConnectionsService.postApiRemoveFromChannels({
          requestBody: {
            connectionId: cid.value,
            channels: removeChannels,
          },
        })
      activeChannels.value = activeChannels.value.filter((c) => !channels.includes(c))
    } catch (err) {
      console.log(`Unable to unsubscribe from channels: ` + err)
    }
  }

  return {
    cid,
    activeChannels,
    setConnectionId,
    subscribeToChannels,
    unsubscribeFromChannels,
  } as const
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRealtimeStore, import.meta.hot))
}
