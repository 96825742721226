/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Add_App_Group_MemberDatasourceRequest } from '../models/Add_App_Group_MemberDatasourceRequest';
import type { Add_Initiative_Group_MemberDatasourceRequest } from '../models/Add_Initiative_Group_MemberDatasourceRequest';
import type { Add_Tenant_Group_MemberDatasourceRequest } from '../models/Add_Tenant_Group_MemberDatasourceRequest';
import type { Create_App_GroupDatasourceRequest } from '../models/Create_App_GroupDatasourceRequest';
import type { Create_App_GroupDatasourceResponseList } from '../models/Create_App_GroupDatasourceResponseList';
import type { Create_AppDatasourceRequest } from '../models/Create_AppDatasourceRequest';
import type { Create_AppDatasourceResponseList } from '../models/Create_AppDatasourceResponseList';
import type { Create_DashboardDatasourceRequest } from '../models/Create_DashboardDatasourceRequest';
import type { Create_DashboardDatasourceResponseList } from '../models/Create_DashboardDatasourceResponseList';
import type { Create_Initiative_GroupDatasourceRequest } from '../models/Create_Initiative_GroupDatasourceRequest';
import type { Create_Initiative_GroupDatasourceResponseList } from '../models/Create_Initiative_GroupDatasourceResponseList';
import type { Create_InitiativeDatasourceRequest } from '../models/Create_InitiativeDatasourceRequest';
import type { Create_InitiativeDatasourceResponseList } from '../models/Create_InitiativeDatasourceResponseList';
import type { Create_Page_SetDatasourceRequest } from '../models/Create_Page_SetDatasourceRequest';
import type { Create_Page_SetDatasourceResponseList } from '../models/Create_Page_SetDatasourceResponseList';
import type { Create_PageDatasourceRequest } from '../models/Create_PageDatasourceRequest';
import type { Create_PageDatasourceResponseList } from '../models/Create_PageDatasourceResponseList';
import type { Create_Tenant_GroupDatasourceRequest } from '../models/Create_Tenant_GroupDatasourceRequest';
import type { Create_Tenant_GroupDatasourceResponseList } from '../models/Create_Tenant_GroupDatasourceResponseList';
import type { Create_TenantDatasourceRequest } from '../models/Create_TenantDatasourceRequest';
import type { Create_TenantDatasourceResponseList } from '../models/Create_TenantDatasourceResponseList';
import type { DatasourceExecExecutionResponse } from '../models/DatasourceExecExecutionResponse';
import type { DatasourceMultiSelectExecutionResponse } from '../models/DatasourceMultiSelectExecutionResponse';
import type { Delete_App_GroupDatasourceRequest } from '../models/Delete_App_GroupDatasourceRequest';
import type { Delete_AppDatasourceRequest } from '../models/Delete_AppDatasourceRequest';
import type { Delete_DashboardDatasourceRequest } from '../models/Delete_DashboardDatasourceRequest';
import type { Delete_Initiative_GroupDatasourceRequest } from '../models/Delete_Initiative_GroupDatasourceRequest';
import type { Delete_InitiativeDatasourceRequest } from '../models/Delete_InitiativeDatasourceRequest';
import type { Delete_Mini_AppDatasourceRequest } from '../models/Delete_Mini_AppDatasourceRequest';
import type { Delete_Page_SetDatasourceRequest } from '../models/Delete_Page_SetDatasourceRequest';
import type { Delete_PageDatasourceRequest } from '../models/Delete_PageDatasourceRequest';
import type { Delete_Tenant_GroupDatasourceRequest } from '../models/Delete_Tenant_GroupDatasourceRequest';
import type { Delete_TenantDatasourceRequest } from '../models/Delete_TenantDatasourceRequest';
import type { Get_All_App_Definitions_For_InitiativesDatasourceRequest } from '../models/Get_All_App_Definitions_For_InitiativesDatasourceRequest';
import type { Get_All_App_Definitions_For_InitiativesDatasourceResponseList } from '../models/Get_All_App_Definitions_For_InitiativesDatasourceResponseList';
import type { Get_All_Mini_App_Definitions_For_InitiativeDatasourceRequest } from '../models/Get_All_Mini_App_Definitions_For_InitiativeDatasourceRequest';
import type { Get_All_Mini_App_Definitions_For_InitiativeDatasourceResponseList } from '../models/Get_All_Mini_App_Definitions_For_InitiativeDatasourceResponseList';
import type { Get_App_Definitions_For_InitiativesDatasourceRequest } from '../models/Get_App_Definitions_For_InitiativesDatasourceRequest';
import type { Get_App_Definitions_For_InitiativesDatasourceResponseList } from '../models/Get_App_Definitions_For_InitiativesDatasourceResponseList';
import type { Get_Chat_Members_Groups_And_ConversationsDatasourceRequest } from '../models/Get_Chat_Members_Groups_And_ConversationsDatasourceRequest';
import type { Get_Chat_Messages_Using_From_And_ToDatasourceRequest } from '../models/Get_Chat_Messages_Using_From_And_ToDatasourceRequest';
import type { Get_Chat_Messages_Using_From_And_ToDatasourceResponseList } from '../models/Get_Chat_Messages_Using_From_And_ToDatasourceResponseList';
import type { Get_Group_And_MembersDatasourceRequest } from '../models/Get_Group_And_MembersDatasourceRequest';
import type { Get_Mini_App_Definitions_For_AppsDatasourceRequest } from '../models/Get_Mini_App_Definitions_For_AppsDatasourceRequest';
import type { Get_Mini_App_Definitions_For_AppsDatasourceResponseList } from '../models/Get_Mini_App_Definitions_For_AppsDatasourceResponseList';
import type { Groups_And_Permissions_ListDatasourceRequest } from '../models/Groups_And_Permissions_ListDatasourceRequest';
import type { Page_Layout_TemplatesDatasourceRequest } from '../models/Page_Layout_TemplatesDatasourceRequest';
import type { Page_Layout_TemplatesDatasourceResponseList } from '../models/Page_Layout_TemplatesDatasourceResponseList';
import type { Post_Chat_MessageDatasourceRequest } from '../models/Post_Chat_MessageDatasourceRequest';
import type { Post_Chat_MessageDatasourceResponseList } from '../models/Post_Chat_MessageDatasourceResponseList';
import type { Remove_App_Group_MemberDatasourceRequest } from '../models/Remove_App_Group_MemberDatasourceRequest';
import type { Remove_Initiative_Group_MemberDatasourceRequest } from '../models/Remove_Initiative_Group_MemberDatasourceRequest';
import type { Remove_Tenant_Group_MemberDatasourceRequest } from '../models/Remove_Tenant_Group_MemberDatasourceRequest';
import type { RouteAppRouteItemsResponse } from '../models/RouteAppRouteItemsResponse';
import type { RouteDashboardRouteItemsResponse } from '../models/RouteDashboardRouteItemsResponse';
import type { RouteInitiativeRouteItemsResponse } from '../models/RouteInitiativeRouteItemsResponse';
import type { RoutePageRouteItemsResponse } from '../models/RoutePageRouteItemsResponse';
import type { RoutePageSetRouteItemsResponse } from '../models/RoutePageSetRouteItemsResponse';
import type { RouteResponse } from '../models/RouteResponse';
import type { Search_For_UsersDatasourceRequest } from '../models/Search_For_UsersDatasourceRequest';
import type { Search_For_UsersDatasourceResponseList } from '../models/Search_For_UsersDatasourceResponseList';
import type { Update_App_GroupDatasourceRequest } from '../models/Update_App_GroupDatasourceRequest';
import type { Update_App_GroupDatasourceResponseList } from '../models/Update_App_GroupDatasourceResponseList';
import type { Update_App_SettingsDatasourceRequest } from '../models/Update_App_SettingsDatasourceRequest';
import type { Update_App_SettingsDatasourceResponseList } from '../models/Update_App_SettingsDatasourceResponseList';
import type { Update_AppDatasourceRequest } from '../models/Update_AppDatasourceRequest';
import type { Update_AppDatasourceResponseList } from '../models/Update_AppDatasourceResponseList';
import type { Update_DashboardDatasourceRequest } from '../models/Update_DashboardDatasourceRequest';
import type { Update_DashboardDatasourceResponseList } from '../models/Update_DashboardDatasourceResponseList';
import type { Update_Initiative_GroupDatasourceRequest } from '../models/Update_Initiative_GroupDatasourceRequest';
import type { Update_Initiative_GroupDatasourceResponseList } from '../models/Update_Initiative_GroupDatasourceResponseList';
import type { Update_InitiativeDatasourceRequest } from '../models/Update_InitiativeDatasourceRequest';
import type { Update_InitiativeDatasourceResponseList } from '../models/Update_InitiativeDatasourceResponseList';
import type { Update_Mini_App_SettingsDatasourceRequest } from '../models/Update_Mini_App_SettingsDatasourceRequest';
import type { Update_Mini_App_SettingsDatasourceResponseList } from '../models/Update_Mini_App_SettingsDatasourceResponseList';
import type { Update_Page_LayoutDatasourceRequest } from '../models/Update_Page_LayoutDatasourceRequest';
import type { Update_Page_SetDatasourceRequest } from '../models/Update_Page_SetDatasourceRequest';
import type { Update_Page_SetDatasourceResponseList } from '../models/Update_Page_SetDatasourceResponseList';
import type { Update_PageDatasourceRequest } from '../models/Update_PageDatasourceRequest';
import type { Update_PageDatasourceResponseList } from '../models/Update_PageDatasourceResponseList';
import type { Update_Permissions_AppDatasourceRequest } from '../models/Update_Permissions_AppDatasourceRequest';
import type { Update_Permissions_DashboardDatasourceRequest } from '../models/Update_Permissions_DashboardDatasourceRequest';
import type { Update_Permissions_InitiativeDatasourceRequest } from '../models/Update_Permissions_InitiativeDatasourceRequest';
import type { Update_Permissions_Mini_AppDatasourceRequest } from '../models/Update_Permissions_Mini_AppDatasourceRequest';
import type { Update_Permissions_Page_SetDatasourceRequest } from '../models/Update_Permissions_Page_SetDatasourceRequest';
import type { Update_Permissions_PageDatasourceRequest } from '../models/Update_Permissions_PageDatasourceRequest';
import type { Update_Permissions_TenantDatasourceRequest } from '../models/Update_Permissions_TenantDatasourceRequest';
import type { Update_Tenant_GroupDatasourceRequest } from '../models/Update_Tenant_GroupDatasourceRequest';
import type { Update_Tenant_GroupDatasourceResponseList } from '../models/Update_Tenant_GroupDatasourceResponseList';
import type { Update_TenantDatasourceRequest } from '../models/Update_TenantDatasourceRequest';
import type { Update_TenantDatasourceResponseList } from '../models/Update_TenantDatasourceResponseList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CoreService {

    /**
     * Retrieve avatar file.
     * @returns binary Success
     * @throws ApiError
     */
    public static getAvatarFileAtRootBase({
mediaFilePath,
xDomain,
xTenant,
width,
height,
}: {
mediaFilePath: string,
xDomain?: string,
xTenant?: string,
width?: number,
height?: number,
}): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/avatars/{mediaFilePath}',
            path: {
                'mediaFilePath': mediaFilePath,
            },
            headers: {
                'x-domain': xDomain,
                'x-tenant': xTenant,
            },
            query: {
                'width': width,
                'height': height,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Retrieve media file.
     * @returns binary Success
     * @throws ApiError
     */
    public static getMediaFileAtRootBase({
mediaFilePath,
xDomain,
xTenant,
download,
}: {
mediaFilePath: string,
xDomain?: string,
xTenant?: string,
download?: boolean,
}): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/files/{mediaFilePath}',
            path: {
                'mediaFilePath': mediaFilePath,
            },
            headers: {
                'x-domain': xDomain,
                'x-tenant': xTenant,
            },
            query: {
                'download': download,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Retrieve avatar file.
     * @returns binary Success
     * @throws ApiError
     */
    public static getAvatarFile({
mediaFilePath,
xDomain,
xTenant,
width,
height,
}: {
mediaFilePath: string,
xDomain?: string,
xTenant?: string,
width?: number,
height?: number,
}): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/avatars/{mediaFilePath}',
            path: {
                'mediaFilePath': mediaFilePath,
            },
            headers: {
                'x-domain': xDomain,
                'x-tenant': xTenant,
            },
            query: {
                'width': width,
                'height': height,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Retrieve media file.
     * @returns binary Success
     * @throws ApiError
     */
    public static getMediaFile({
mediaFilePath,
xDomain,
xTenant,
download,
}: {
mediaFilePath: string,
xDomain?: string,
xTenant?: string,
download?: boolean,
}): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/files/{mediaFilePath}',
            path: {
                'mediaFilePath': mediaFilePath,
            },
            headers: {
                'x-domain': xDomain,
                'x-tenant': xTenant,
            },
            query: {
                'download': download,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Retrieve all pertinent data for the RTD web app route.
     * @returns RouteResponse Success
     * @throws ApiError
     */
    public static getRouteAtRoot({
xDomain,
xTenant,
}: {
xDomain?: string,
xTenant?: string,
}): CancelablePromise<RouteResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/route',
            headers: {
                'x-domain': xDomain,
                'x-tenant': xTenant,
            },
        });
    }

    /**
     * Retrieve all pertinent data for the RTD web app route.
     * @returns RouteResponse Success
     * @throws ApiError
     */
    public static getRouteAtPath({
routePath,
xDomain,
xTenant,
}: {
routePath: string,
xDomain?: string,
xTenant?: string,
}): CancelablePromise<RouteResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/route/{RoutePath}',
            path: {
                'RoutePath': routePath,
            },
            headers: {
                'x-domain': xDomain,
                'x-tenant': xTenant,
            },
        });
    }

    /**
     * Retrieve list of initiatives inside tenant.
     * @returns RouteInitiativeRouteItemsResponse Success
     * @throws ApiError
     */
    public static getInitiativesInTenant({
tenantId,
}: {
tenantId: string,
}): CancelablePromise<RouteInitiativeRouteItemsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/list/{tenantId}/initiatives',
            path: {
                'tenantId': tenantId,
            },
        });
    }

    /**
     * Retrieve list of apps inside initiative.
     * @returns RouteAppRouteItemsResponse Success
     * @throws ApiError
     */
    public static getAppsInInitiative({
tenantId,
initiativeId,
}: {
tenantId: string,
initiativeId: string,
}): CancelablePromise<RouteAppRouteItemsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/list/{tenantId}/{initiativeId}/apps',
            path: {
                'tenantId': tenantId,
                'initiativeId': initiativeId,
            },
        });
    }

    /**
     * Retrieve list of dashboards inside app.
     * @returns RouteDashboardRouteItemsResponse Success
     * @throws ApiError
     */
    public static getDashboardsInApp({
tenantId,
initiativeId,
appId,
}: {
tenantId: string,
initiativeId: string,
appId: string,
}): CancelablePromise<RouteDashboardRouteItemsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/list/{tenantId}/{initiativeId}/{appId}/dashboards',
            path: {
                'tenantId': tenantId,
                'initiativeId': initiativeId,
                'appId': appId,
            },
        });
    }

    /**
     * Retrieve list of pagesets inside dashboard.
     * @returns RoutePageSetRouteItemsResponse Success
     * @throws ApiError
     */
    public static getPageSetsInDashboard({
tenantId,
initiativeId,
appId,
dashboardId,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
}): CancelablePromise<RoutePageSetRouteItemsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/list/{tenantId}/{initiativeId}/{appId}/{dashboardId}/page-sets',
            path: {
                'tenantId': tenantId,
                'initiativeId': initiativeId,
                'appId': appId,
                'dashboardId': dashboardId,
            },
        });
    }

    /**
     * Retrieve list of pages inside pageset.
     * @returns RoutePageRouteItemsResponse Success
     * @throws ApiError
     */
    public static getPagesInPageSet({
tenantId,
initiativeId,
appId,
dashboardId,
pageSetId,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
pageSetId: string,
}): CancelablePromise<RoutePageRouteItemsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/list/{tenantId}/{initiativeId}/{appId}/{dashboardId}/{pageSetId}/pages',
            path: {
                'tenantId': tenantId,
                'initiativeId': initiativeId,
                'appId': appId,
                'dashboardId': dashboardId,
                'pageSetId': pageSetId,
            },
        });
    }

    /**
     * Ping the server to see if it's up and running
     * @returns string Success
     * @throws ApiError
     */
    public static getApiPing(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ping',
        });
    }

    /**
     * Basic information about the system
     * @returns any Success
     * @throws ApiError
     */
    public static getApiSystem(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/system',
        });
    }

    /**
     * Delete a tenant group
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static deleteTenantGroup({
tenantId,
requestBody,
}: {
tenantId: string,
requestBody?: Delete_Tenant_GroupDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/delete-tenant-group/{TenantId}',
            path: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create an initiative
     * @returns Create_InitiativeDatasourceResponseList 
     * @throws ApiError
     */
    public static createInitiative({
tenantId,
requestBody,
}: {
tenantId: string,
requestBody?: Create_InitiativeDatasourceRequest,
}): CancelablePromise<Create_InitiativeDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/create-initiative/{TenantId}',
            path: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create a PageSet
     * @returns Create_Page_SetDatasourceResponseList 
     * @throws ApiError
     */
    public static createPageSet({
tenantId,
initiativeId,
appId,
dashboardId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
requestBody?: Create_Page_SetDatasourceRequest,
}): CancelablePromise<Create_Page_SetDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/create-page-set/{TenantId}/{InitiativeId}/{AppId}/{DashboardId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
                'DashboardId': dashboardId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete an initiative group
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static deleteInitiativeGroup({
tenantId,
initiativeId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
requestBody?: Delete_Initiative_GroupDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/delete-initiative-group/{TenantId}/{InitiativeId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete a PageSet
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static deletePageSet({
tenantId,
initiativeId,
appId,
dashboardId,
pageSetId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
pageSetId: string,
requestBody?: Delete_Page_SetDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/delete-page-set/{TenantId}/{InitiativeId}/{AppId}/{DashboardId}/{PageSetId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
                'DashboardId': dashboardId,
                'PageSetId': pageSetId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Posts a chat message
     * @returns Post_Chat_MessageDatasourceResponseList 
     * @throws ApiError
     */
    public static postChatMessage({
tenantId,
requestBody,
}: {
tenantId: string,
requestBody?: Post_Chat_MessageDatasourceRequest,
}): CancelablePromise<Post_Chat_MessageDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/post-chat-message/{TenantId}',
            path: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete a Page
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static deletePage({
tenantId,
initiativeId,
appId,
dashboardId,
pageSetId,
pageId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
pageSetId: string,
pageId: string,
requestBody?: Delete_PageDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/delete-page/{TenantId}/{InitiativeId}/{AppId}/{DashboardId}/{PageSetId}/{PageId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
                'DashboardId': dashboardId,
                'PageSetId': pageSetId,
                'PageId': pageId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update permissions on a mini-app
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static updatePermissionsforMiniApp({
tenantId,
initiativeId,
appId,
dashboardId,
pageSetId,
pageId,
miniAppId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
pageSetId: string,
pageId: string,
miniAppId: string,
requestBody?: Update_Permissions_Mini_AppDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-permissions-mini-app/{TenantId}/{InitiativeId}/{AppId}/{DashboardId}/{PageSetId}/{PageId}/{MiniAppId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
                'DashboardId': dashboardId,
                'PageSetId': pageSetId,
                'PageId': pageId,
                'MiniAppId': miniAppId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Updates a page layout
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static updatePageLayout({
tenantId,
initiativeId,
appId,
dashboardId,
pageSetId,
pageId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
pageSetId: string,
pageId: string,
requestBody?: Update_Page_LayoutDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-page-layout/{TenantId}/{InitiativeId}/{AppId}/{DashboardId}/{PageSetId}/{PageId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
                'DashboardId': dashboardId,
                'PageSetId': pageSetId,
                'PageId': pageId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update a tenant
     * @returns Update_TenantDatasourceResponseList 
     * @throws ApiError
     */
    public static updateTenant({
tenantId,
requestBody,
}: {
tenantId: string,
requestBody?: Update_TenantDatasourceRequest,
}): CancelablePromise<Update_TenantDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-tenant/{TenantId}',
            path: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update an app group
     * @returns Update_App_GroupDatasourceResponseList 
     * @throws ApiError
     */
    public static updateAppGroup({
tenantId,
initiativeId,
appId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
requestBody?: Update_App_GroupDatasourceRequest,
}): CancelablePromise<Update_App_GroupDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-app-group/{TenantId}/{InitiativeId}/{AppId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Remove a member from a tenant group
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static removeTenantGroupMember({
tenantId,
requestBody,
}: {
tenantId: string,
requestBody?: Remove_Tenant_Group_MemberDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/remove-tenant-group-member/{TenantId}',
            path: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update permissions on an app
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static updatePermissionsforApp({
tenantId,
initiativeId,
appId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
requestBody?: Update_Permissions_AppDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-permissions-app/{TenantId}/{InitiativeId}/{AppId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create a Page
     * @returns Create_PageDatasourceResponseList 
     * @throws ApiError
     */
    public static createPage({
tenantId,
initiativeId,
appId,
dashboardId,
pageSetId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
pageSetId: string,
requestBody?: Create_PageDatasourceRequest,
}): CancelablePromise<Create_PageDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/create-page/{TenantId}/{InitiativeId}/{AppId}/{DashboardId}/{PageSetId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
                'DashboardId': dashboardId,
                'PageSetId': pageSetId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update permissions on a tenant
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static updatePermissionsforTenant({
tenantId,
requestBody,
}: {
tenantId: string,
requestBody?: Update_Permissions_TenantDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-permissions-tenant/{TenantId}',
            path: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update permissions on an initiative
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static updatePermissionsforInitiative({
tenantId,
initiativeId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
requestBody?: Update_Permissions_InitiativeDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-permissions-initiative/{TenantId}/{InitiativeId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create a tenant
     * @returns Create_TenantDatasourceResponseList 
     * @throws ApiError
     */
    public static createTenant({
requestBody,
}: {
requestBody?: Create_TenantDatasourceRequest,
}): CancelablePromise<Create_TenantDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/create-tenant',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Retrieve the list of messages using from and to.
     * @returns Get_Chat_Messages_Using_From_And_ToDatasourceResponseList 
     * @throws ApiError
     */
    public static getChatMessagesUsingFromAndTo({
tenantId,
requestBody,
}: {
tenantId: string,
requestBody?: Get_Chat_Messages_Using_From_And_ToDatasourceRequest,
}): CancelablePromise<Get_Chat_Messages_Using_From_And_ToDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/get-chat-messages-using-from-and-to/{TenantId}',
            path: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update a PageSet
     * @returns Update_Page_SetDatasourceResponseList 
     * @throws ApiError
     */
    public static updatePageSet({
tenantId,
initiativeId,
appId,
dashboardId,
pageSetId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
pageSetId: string,
requestBody?: Update_Page_SetDatasourceRequest,
}): CancelablePromise<Update_Page_SetDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-page-set/{TenantId}/{InitiativeId}/{AppId}/{DashboardId}/{PageSetId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
                'DashboardId': dashboardId,
                'PageSetId': pageSetId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update permissions on a page-set
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static updatePermissionsforPageSet({
tenantId,
initiativeId,
appId,
dashboardId,
pageSetId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
pageSetId: string,
requestBody?: Update_Permissions_Page_SetDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-permissions-page-set/{TenantId}/{InitiativeId}/{AppId}/{DashboardId}/{PageSetId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
                'DashboardId': dashboardId,
                'PageSetId': pageSetId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update app settings
     * @returns Update_App_SettingsDatasourceResponseList 
     * @throws ApiError
     */
    public static updateAppSettings({
tenantId,
initiativeId,
appId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
requestBody?: Update_App_SettingsDatasourceRequest,
}): CancelablePromise<Update_App_SettingsDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-app-settings/{TenantId}/{InitiativeId}/{AppId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Remove a member from an app group
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static removeAppGroupMember({
tenantId,
initiativeId,
appId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
requestBody?: Remove_App_Group_MemberDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/remove-app-group-member/{TenantId}/{InitiativeId}/{AppId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create an app
     * @returns Create_AppDatasourceResponseList 
     * @throws ApiError
     */
    public static createApp({
tenantId,
initiativeId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
requestBody?: Create_AppDatasourceRequest,
}): CancelablePromise<Create_AppDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/create-app/{TenantId}/{InitiativeId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get the list of groups and permissions for the provided scope
     * @returns DatasourceMultiSelectExecutionResponse 
     * @throws ApiError
     */
    public static getGroupsAndPermissions({
tenantId,
requestBody,
}: {
tenantId: string,
requestBody?: Groups_And_Permissions_ListDatasourceRequest,
}): CancelablePromise<DatasourceMultiSelectExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/groups-and-permissions-list/{TenantId}',
            path: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Retrieves the page layout templates available globally or for the current app definition
     * @returns Page_Layout_TemplatesDatasourceResponseList 
     * @throws ApiError
     */
    public static getPageLayoutTemplates({
requestBody,
}: {
requestBody?: Page_Layout_TemplatesDatasourceRequest,
}): CancelablePromise<Page_Layout_TemplatesDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/page-layout-templates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update a page
     * @returns Update_PageDatasourceResponseList 
     * @throws ApiError
     */
    public static updatePage({
tenantId,
initiativeId,
appId,
dashboardId,
pageSetId,
pageId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
pageSetId: string,
pageId: string,
requestBody?: Update_PageDatasourceRequest,
}): CancelablePromise<Update_PageDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-page/{TenantId}/{InitiativeId}/{AppId}/{DashboardId}/{PageSetId}/{PageId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
                'DashboardId': dashboardId,
                'PageSetId': pageSetId,
                'PageId': pageId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Retrieve the list of members, groups, and conversations for the chat panel.
     * @returns DatasourceMultiSelectExecutionResponse 
     * @throws ApiError
     */
    public static getChatMembersGroupsandConversations({
tenantId,
requestBody,
}: {
tenantId: string,
requestBody?: Get_Chat_Members_Groups_And_ConversationsDatasourceRequest,
}): CancelablePromise<DatasourceMultiSelectExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/get-chat-members-groups-and-conversations/{TenantId}',
            path: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create an app group
     * @returns Create_App_GroupDatasourceResponseList 
     * @throws ApiError
     */
    public static createAppGroup({
tenantId,
initiativeId,
appId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
requestBody?: Create_App_GroupDatasourceRequest,
}): CancelablePromise<Create_App_GroupDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/create-app-group/{TenantId}/{InitiativeId}/{AppId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete an app group
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static deleteAppGroup({
tenantId,
initiativeId,
appId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
requestBody?: Delete_App_GroupDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/delete-app-group/{TenantId}/{InitiativeId}/{AppId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete an app
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static deleteApp({
tenantId,
initiativeId,
appId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
requestBody?: Delete_AppDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/delete-app/{TenantId}/{InitiativeId}/{AppId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Retrieves the list of all installed app definitions for to an initiative
     * @returns Get_All_App_Definitions_For_InitiativesDatasourceResponseList 
     * @throws ApiError
     */
    public static getAllAppDefinitionsForInitiative({
tenantId,
initiativeId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
requestBody?: Get_All_App_Definitions_For_InitiativesDatasourceRequest,
}): CancelablePromise<Get_All_App_Definitions_For_InitiativesDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/get-all-app-definitions-for-initiatives/{TenantId}/{InitiativeId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete an initiative
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static deleteInitiative({
tenantId,
initiativeId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
requestBody?: Delete_InitiativeDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/delete-initiative/{TenantId}/{InitiativeId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Add a member to an initiative group
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static addInitiativeGroupMember({
tenantId,
initiativeId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
requestBody?: Add_Initiative_Group_MemberDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/add-initiative-group-member/{TenantId}/{InitiativeId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Add a member to an app group
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static addAppGroupMember({
tenantId,
initiativeId,
appId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
requestBody?: Add_App_Group_MemberDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/add-app-group-member/{TenantId}/{InitiativeId}/{AppId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete a mini-app
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static deleteMiniApp({
tenantId,
initiativeId,
appId,
dashboardId,
pageSetId,
pageId,
miniAppId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
pageSetId: string,
pageId: string,
miniAppId: string,
requestBody?: Delete_Mini_AppDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/delete-mini-app/{TenantId}/{InitiativeId}/{AppId}/{DashboardId}/{PageSetId}/{PageId}/{MiniAppId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
                'DashboardId': dashboardId,
                'PageSetId': pageSetId,
                'PageId': pageId,
                'MiniAppId': miniAppId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete a tenant
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static deleteTenant({
tenantId,
requestBody,
}: {
tenantId: string,
requestBody?: Delete_TenantDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/delete-tenant/{TenantId}',
            path: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update an initiative group
     * @returns Update_Initiative_GroupDatasourceResponseList 
     * @throws ApiError
     */
    public static updateInitiativeGroup({
tenantId,
initiativeId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
requestBody?: Update_Initiative_GroupDatasourceRequest,
}): CancelablePromise<Update_Initiative_GroupDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-initiative-group/{TenantId}/{InitiativeId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Add a member to a tenant group
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static addTenantGroupMember({
tenantId,
requestBody,
}: {
tenantId: string,
requestBody?: Add_Tenant_Group_MemberDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/add-tenant-group-member/{TenantId}',
            path: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update an initiative
     * @returns Update_InitiativeDatasourceResponseList 
     * @throws ApiError
     */
    public static updateInitiative({
tenantId,
initiativeId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
requestBody?: Update_InitiativeDatasourceRequest,
}): CancelablePromise<Update_InitiativeDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-initiative/{TenantId}/{InitiativeId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update a tenant group
     * @returns Update_Tenant_GroupDatasourceResponseList 
     * @throws ApiError
     */
    public static updateTenantGroup({
tenantId,
requestBody,
}: {
tenantId: string,
requestBody?: Update_Tenant_GroupDatasourceRequest,
}): CancelablePromise<Update_Tenant_GroupDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-tenant-group/{TenantId}',
            path: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Retrieves the list of app definitions available for an initiative to install
     * @returns Get_App_Definitions_For_InitiativesDatasourceResponseList 
     * @throws ApiError
     */
    public static getAppDefinitionsForInitiative({
tenantId,
requestBody,
}: {
tenantId: string,
requestBody?: Get_App_Definitions_For_InitiativesDatasourceRequest,
}): CancelablePromise<Get_App_Definitions_For_InitiativesDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/get-app-definitions-for-initiatives/{TenantId}',
            path: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update the settings of a mini-app
     * @returns Update_Mini_App_SettingsDatasourceResponseList 
     * @throws ApiError
     */
    public static updateMiniAppSettings({
tenantId,
initiativeId,
appId,
dashboardId,
pageSetId,
pageId,
miniAppId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
pageSetId: string,
pageId: string,
miniAppId: string,
requestBody?: Update_Mini_App_SettingsDatasourceRequest,
}): CancelablePromise<Update_Mini_App_SettingsDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-mini-app-settings/{TenantId}/{InitiativeId}/{AppId}/{DashboardId}/{PageSetId}/{PageId}/{MiniAppId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
                'DashboardId': dashboardId,
                'PageSetId': pageSetId,
                'PageId': pageId,
                'MiniAppId': miniAppId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Search for users
     * @returns Search_For_UsersDatasourceResponseList 
     * @throws ApiError
     */
    public static searchForUsers({
tenantId,
requestBody,
}: {
tenantId: string,
requestBody?: Search_For_UsersDatasourceRequest,
}): CancelablePromise<Search_For_UsersDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/search-for-users/{TenantId}',
            path: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Remove a member from an initiative group
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static removeInitiativeGroupMember({
tenantId,
initiativeId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
requestBody?: Remove_Initiative_Group_MemberDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/remove-initiative-group-member/{TenantId}/{InitiativeId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update permissions on a page
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static updatePermissionsforPage({
tenantId,
initiativeId,
appId,
dashboardId,
pageSetId,
pageId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
pageSetId: string,
pageId: string,
requestBody?: Update_Permissions_PageDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-permissions-page/{TenantId}/{InitiativeId}/{AppId}/{DashboardId}/{PageSetId}/{PageId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
                'DashboardId': dashboardId,
                'PageSetId': pageSetId,
                'PageId': pageId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update a dashboard
     * @returns Update_DashboardDatasourceResponseList 
     * @throws ApiError
     */
    public static updateDashboard({
tenantId,
initiativeId,
appId,
dashboardId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
requestBody?: Update_DashboardDatasourceRequest,
}): CancelablePromise<Update_DashboardDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-dashboard/{TenantId}/{InitiativeId}/{AppId}/{DashboardId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
                'DashboardId': dashboardId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create a tenant group
     * @returns Create_Tenant_GroupDatasourceResponseList 
     * @throws ApiError
     */
    public static createTenantGroup({
tenantId,
requestBody,
}: {
tenantId: string,
requestBody?: Create_Tenant_GroupDatasourceRequest,
}): CancelablePromise<Create_Tenant_GroupDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/create-tenant-group/{TenantId}',
            path: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Retrieves the available mini app definitions for a particular app
     * @returns Get_Mini_App_Definitions_For_AppsDatasourceResponseList 
     * @throws ApiError
     */
    public static getMiniAppDefinitionsForApp({
requestBody,
}: {
requestBody?: Get_Mini_App_Definitions_For_AppsDatasourceRequest,
}): CancelablePromise<Get_Mini_App_Definitions_For_AppsDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/get-mini-app-definitions-for-apps',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create a dashboard
     * @returns Create_DashboardDatasourceResponseList 
     * @throws ApiError
     */
    public static createDashboard({
tenantId,
initiativeId,
appId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
requestBody?: Create_DashboardDatasourceRequest,
}): CancelablePromise<Create_DashboardDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/create-dashboard/{TenantId}/{InitiativeId}/{AppId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Retrieves the installed mini app definitions for a particular initiative
     * @returns Get_All_Mini_App_Definitions_For_InitiativeDatasourceResponseList 
     * @throws ApiError
     */
    public static getAllMiniAppDefinitionsForInitiative({
tenantId,
initiativeId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
requestBody?: Get_All_Mini_App_Definitions_For_InitiativeDatasourceRequest,
}): CancelablePromise<Get_All_Mini_App_Definitions_For_InitiativeDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/get-all-mini-app-definitions-for-initiative/{TenantId}/{InitiativeId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete a dashboard
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static deleteDashboard({
tenantId,
initiativeId,
appId,
dashboardId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
requestBody?: Delete_DashboardDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/delete-dashboard/{TenantId}/{InitiativeId}/{AppId}/{DashboardId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
                'DashboardId': dashboardId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update permissions on a dashboard
     * @returns DatasourceExecExecutionResponse 
     * @throws ApiError
     */
    public static updatePermissionsforDashboard({
tenantId,
initiativeId,
appId,
dashboardId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
dashboardId: string,
requestBody?: Update_Permissions_DashboardDatasourceRequest,
}): CancelablePromise<DatasourceExecExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-permissions-dashboard/{TenantId}/{InitiativeId}/{AppId}/{DashboardId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
                'DashboardId': dashboardId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create an initiative group
     * @returns Create_Initiative_GroupDatasourceResponseList 
     * @throws ApiError
     */
    public static createInitiativeGroup({
tenantId,
initiativeId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
requestBody?: Create_Initiative_GroupDatasourceRequest,
}): CancelablePromise<Create_Initiative_GroupDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/create-initiative-group/{TenantId}/{InitiativeId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update an app
     * @returns Update_AppDatasourceResponseList 
     * @throws ApiError
     */
    public static updateApp({
tenantId,
initiativeId,
appId,
requestBody,
}: {
tenantId: string,
initiativeId: string,
appId: string,
requestBody?: Update_AppDatasourceRequest,
}): CancelablePromise<Update_AppDatasourceResponseList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/update-app/{TenantId}/{InitiativeId}/{AppId}',
            path: {
                'TenantId': tenantId,
                'InitiativeId': initiativeId,
                'AppId': appId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get a group and it's members
     * @returns DatasourceMultiSelectExecutionResponse 
     * @throws ApiError
     */
    public static getGroupAndMembers({
tenantId,
requestBody,
}: {
tenantId: string,
requestBody?: Get_Group_And_MembersDatasourceRequest,
}): CancelablePromise<DatasourceMultiSelectExecutionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ds/get-group-and-members/{TenantId}',
            path: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Exception`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

}
