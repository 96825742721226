/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivityHeartbeatForChannelsRequest } from '../models/ActivityHeartbeatForChannelsRequest';
import type { LastActivityForChannelsRequest } from '../models/LastActivityForChannelsRequest';
import type { LastActivityForChannelsResponse } from '../models/LastActivityForChannelsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChannelActivityService {

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiActivityHeartbeat({
requestBody,
}: {
requestBody: ActivityHeartbeatForChannelsRequest,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/activity-heartbeat',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LastActivityForChannelsResponse Success
     * @throws ApiError
     */
    public static postApiLastActivity({
requestBody,
}: {
requestBody: LastActivityForChannelsRequest,
}): CancelablePromise<LastActivityForChannelsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/last-activity',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
